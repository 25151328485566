import {
  PricingBreakDown,
  PricingList,
} from "@api/graphql/generated/generated-types";
import {
  Badge,
  BaseModal,
  BaseModalBody,
  BaseModalHeader,
} from "@src/common/components";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  breakdown: PricingBreakDown;
  total: string;
  pricingList: PricingList | undefined | null;
};

export function PricingBreakDownModal({
  isOpen,
  onClose,
  breakdown,
  total,
  pricingList,
}: Props) {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <BaseModalHeader>Price Breakdown</BaseModalHeader>
      <BaseModalBody>
        <div className="container mx-auto">
          <table className="w-full border-collapse">
            <tbody>
              {pricingList && (
                <tr key={"total"}>
                  <td className="py-4">
                    <div className="font-semibold">Pricing List</div>
                  </td>

                  <td className="font-semibold py-4 flex">
                    <Badge color={"purple"}>{pricingList?.name}</Badge>
                  </td>
                </tr>
              )}
              {breakdown.items.map((item, index) => (
                <tr key={index}>
                  <td className="py-2">
                    <div className="flex flex-col">
                      <p>{item.name}</p>
                    </div>
                    {item.description && (
                      <div className="text-slate-400">
                        <p>{item.description}</p>
                      </div>
                    )}
                  </td>
                  <td className="py-2">${item.amount}</td>
                </tr>
              ))}
              <tr key={"total"}>
                <td className="py-4">
                  <div className="font-semibold">Total</div>
                </td>
                <td className="font-semibold py-4">${total}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </BaseModalBody>
    </BaseModal>
  );
}

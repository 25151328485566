import React from "react";

type Props = {
  text: string;
  length: number;
  offset: number;
};

const BoldSubstring = ({ text, length, offset }: Props) => {
  if (!text || length < 1 || offset < 0) {
    return <>{text}</>;
  }

  const boldText = text.slice(offset, offset + length);
  const beforeBold = text.slice(0, offset);
  const afterBold = text.slice(offset + length);

  return (
    <p>
      {beforeBold}
      <strong>{boldText}</strong>
      {afterBold}
    </p>
  );
};

export default BoldSubstring;

import { useCallback, useState } from "react";

export const useGooglePlaceDetails = () => {
  const [place, setPlace] =
    useState<google.maps.places.PlaceResult | null>(null);

  const fetchPlaceDetails = useCallback((placeId: string) => {
    const div = document.createElement("div");
    const placesService = new google.maps.places.PlacesService(div);

    placesService.getDetails({ placeId }, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && place) {
        setPlace(place);
      }
    });
  }, []);

  return { place, fetchPlaceDetails };
};

import { Select } from "@src/common/components";
import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";
import { PackageSize } from "@api/graphql/generated/generated-types";
import { displayPackageSize } from "@src/shipments/utils/displayPackageSize";

type Props = {
  control: Control<FieldValues>;
  size?: "mini" | "default" | "compact" | "large" | undefined;
  className?: string;
  label?: React.ReactNode;
  name: string;
  stringOnly?: boolean;
};

const PackageSizeWithLabel = [
  { id: PackageSize.Xs, label: displayPackageSize(PackageSize.Xs) },
  { id: PackageSize.S, label: displayPackageSize(PackageSize.S) },
  { id: PackageSize.M, label: displayPackageSize(PackageSize.M) },
  { id: PackageSize.L, label: displayPackageSize(PackageSize.L) },
  { id: PackageSize.Xl, label: displayPackageSize(PackageSize.Xl) },
  { id: PackageSize.Xxl, label: displayPackageSize(PackageSize.Xxl) },
];

function SelectPackage({
  control,
  size = "default",
  className,
  label,
  name,
  stringOnly = false,
}: Props) {
  return (
    <div className={`flex flex-col ${className}`}>
      {label}
      <Controller
        name={name}
        control={control}
        render={({
          field: { value, onChange },
          fieldState: { isDirty, error },
        }) => {
          const selectedPackage = PackageSizeWithLabel.find(
            (pkg) => pkg.id === value
          );
          return (
            <Select
              size={size}
              options={PackageSizeWithLabel}
              value={
                typeof value === "string" ? (selectedPackage as any) : value
              }
              placeholder={value ? "" : "Select Package Size"}
              onChange={(params) => {
                if (stringOnly) {
                  const value = params.value[0].id;
                  onChange(value);
                } else {
                  onChange(params.value);
                }
              }}
              positive={isDirty && !error && value.length > 0}
            />
          );
        }}
      />
    </div>
  );
}

export default SelectPackage;

import React, { useEffect, useState } from "react";
import { Timeline } from "flowbite-react";
import { formatTitleCaseWithUnderscores } from "@src/common/lib/textUtils";
import { useSpring, animated } from "react-spring";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import {
  PackageItem,
  PricingBreakDown,
  PricingList,
  Stop,
} from "@api/graphql/generated/generated-types";
import { PricingBreakDownModal } from "./modals/PricingBreakdownModal";
import InternalNote, { InternalNotePanelProps } from "./InternalNote";
import classNames from "classnames";
import { LayersManager } from "baseui/layer";
import { title } from "process";

export type GeneralPanelProps = {
  title: string;
  value: string;
};

export type StopPanelPropsBodyItem = {
  title: string;
  value: string;
  className?: string;
};

export type PricingPanelProps = {
  title: string | number;
  value: string;
  breakdown: PricingBreakDown | undefined;
  pricingList: PricingList | undefined | null;
  showAnimation?: boolean;
};

export type StopPanelProps = {
  title: string;
  subtext?: string;
  bodyItems?: StopPanelPropsBodyItem[];
  stop?: Stop | undefined;
};

export type OrderDetailsWidgetDataProps = {
  panelName:
    | "General"
    | "Stops"
    | "Pricing"
    | "internalNote"
    | "Package Items"
    | "Package Description";
  generalPanelProps?: GeneralPanelProps[];
  stopPanelProps?: StopPanelProps[];
  pricingPanelProps?: PricingPanelProps;
  internalNotePanelProps?: InternalNotePanelProps;
  packageItems?: PackageItem[];
  packageDescription?: string;
};

interface NumberIconProps extends React.SVGProps<SVGSVGElement> {
  index: number;
}

type Props = {
  orderDetails: OrderDetailsWidgetDataProps[];
};

export const NumberIcon: React.FC<NumberIconProps> = ({ index, ...props }) => (
  <svg width="30" height="30" {...props}>
    <text
      x="50%"
      y="50%"
      textAnchor="middle"
      dy=".3em"
      fontSize="16"
      fontWeight="bold"
    >
      {index}
    </text>
  </svg>
);

const OrderDetailsWidget = ({ orderDetails = [] }: Props) => {
  const [animatedValue, setAnimatedValue] = useState<any>(0);
  const [pricingBreakdownModalOpen, setPricingBreakdownModalOpen] =
    useState(false);
  const { number } = useSpring({
    from: { number: 0 },
    to: {
      number: animatedValue,
    },
    reset: false,
    delay: 0,
  });

  useEffect(() => {
    if (Array.isArray(orderDetails)) {
      let pricingPanelFound = orderDetails.find(
        (panel) => panel.panelName === "Pricing"
      );
      if (
        pricingPanelFound &&
        typeof pricingPanelFound.pricingPanelProps?.title === "number"
      ) {
        if (pricingPanelFound.pricingPanelProps?.showAnimation) {
          const numberTitle = pricingPanelFound.pricingPanelProps?.title;
          setAnimatedValue(numberTitle);
        }
      }
    }
  }, [orderDetails]);

  const hasDimensions = (item: PackageItem): boolean => {
    return (
      item.width !== null ||
      item.height !== null ||
      item.length !== null ||
      item.weight !== null
    );
  };

  if (orderDetails.length) {
    return (
      <div className={`p-3 bg-white dark:bg-gray-800 border rounded-2xl`}>
        {orderDetails.map((panel: OrderDetailsWidgetDataProps, i) => {
          if (panel.panelName === "internalNote") {
            return (
              <div key={i}>
                <span className="font-semibold">Note</span>
                <InternalNote key={i} {...panel.internalNotePanelProps} />
              </div>
            );
          }

          if (panel.panelName === "Stops") {
            return (
              <div
                key={i}
                className="flex flex-col gap-2 bg-white rounded-md p-3 pt-2 text-gray-600"
              >
                <span className="font-semibold -ml-3">
                  Stops ({panel.stopPanelProps?.length})
                </span>
                {panel.stopPanelProps?.map(
                  (item: StopPanelProps, i: number) => {
                    const completed = item.stop?.completedAt ? true : false;
                    return (
                      <Timeline key={i}>
                        <Timeline.Item>
                          <Timeline.Point
                            icon={() => (
                              <div style={{ height: 30, width: 30 }}>
                                <NumberIcon
                                  index={i + 1}
                                  className={classNames("rounded-full", {
                                    "border rounded-full": true,
                                    "bg-green-300": completed,
                                    "bg-white": !completed,
                                  })}
                                />
                              </div>
                            )}
                          />
                          <Timeline.Content>
                            <Timeline.Title className="font-semibold text-base">
                              {formatTitleCaseWithUnderscores(item.title)}
                            </Timeline.Title>
                            <Timeline.Time>{item.subtext}</Timeline.Time>
                            <Timeline.Body>
                              <StopItemBody {...item} />
                            </Timeline.Body>
                          </Timeline.Content>
                        </Timeline.Item>
                      </Timeline>
                    );
                  }
                )}
              </div>
            );
          }
          if (panel.panelName === "Pricing") {
            return (
              <div key={i}>
                <span className="font-semibold">Price</span>
                <div className="grid grid-cols-2 items-center justify-between bg-slate-100 rounded-md p-3 text-gray-600">
                  <div className="flex flex-col gap-2 col-span-1">
                    <span className="text-xl font-bold">
                      {typeof panel.pricingPanelProps?.title === "number" ? (
                        <span className="text-xl font-bold">
                          {panel.pricingPanelProps.showAnimation ? (
                            <animated.span className="text-xl font-bold">
                              {number.to((n: any) => `$ ${n.toFixed(2)}`)}
                            </animated.span>
                          ) : (
                            <span>{panel.pricingPanelProps?.title}</span>
                          )}
                        </span>
                      ) : (
                        panel.pricingPanelProps?.title
                      )}
                    </span>
                    <div key={i} className="text-sm">
                      <span className="font-normal">
                        {panel.pricingPanelProps?.value}
                      </span>
                    </div>
                  </div>
                  {panel.pricingPanelProps?.breakdown && (
                    <a
                      className="col-span-1 flex justify-end"
                      onClick={() => setPricingBreakdownModalOpen(true)}
                    >
                      <QuestionMarkCircleIcon className="h-6 w-6 mr-2 hover:text-slate-900 text-slate-500 cursor-pointer" />
                    </a>
                  )}
                  {panel.pricingPanelProps?.breakdown && (
                    <LayersManager zIndex={20}>
                      <PricingBreakDownModal
                        isOpen={pricingBreakdownModalOpen}
                        onClose={() => setPricingBreakdownModalOpen(false)}
                        breakdown={panel.pricingPanelProps?.breakdown}
                        total={panel.pricingPanelProps?.title.toString()}
                        pricingList={panel.pricingPanelProps?.pricingList}
                      />
                    </LayersManager>
                  )}
                </div>
              </div>
            );
          }
          if (panel.panelName === "Package Description") {
            return (
              <div key={"Package Description"}>
                <span className="font-semibold">Package Description</span>
                <div className="flex flex-col gap-2 rounded-md border p-2 text-gray-600">
                  <div className="text-sm">{panel.packageDescription}</div>
                </div>
              </div>
            );
          }
          if (panel.panelName === "Package Items") {
            if (!panel.packageItems || panel.packageItems.length === 0) {
              return null;
            }
            const itemLabel =
              panel.packageItems.length === 1 ? "Package" : "Packages";

            return (
              <div key={i}>
                <span className="font-semibold">
                  {itemLabel} ({panel.packageItems.length})
                </span>
                <div className="grid grid-cols-2 border rounded-md ">
                  {panel.packageItems.map((item, index) => (
                    <div key={index} className="p-2 w-full">
                      <div className="h-full flex flex-col items-center text-center bg-white rounded-lg overflow-hidden border shadow-md">
                        <div className="w-full py-2">
                          <div className="font-bold mb-1">
                            Package Item {index + 1}
                          </div>
                          {hasDimensions(item) ? (
                            <>
                              {item.width && (
                                <p className="text-gray-700 mb-1">
                                  Width: {item.width} in
                                </p>
                              )}
                              {item.height && (
                                <p className="text-gray-700 mb-1">
                                  Height: {item.height} in
                                </p>
                              )}
                              {item.length && (
                                <p className="text-gray-700 mb-1">
                                  Length: {item.length} in
                                </p>
                              )}
                              {item.weight && (
                                <p className="text-gray-700">
                                  Weight: {item.weight} lbs
                                </p>
                              )}
                            </>
                          ) : (
                            <p className="text-gray-700 px-3 text-sm">
                              Details unavailable
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          }
          if (panel.panelName === "General") {
            return (
              <div key={i} className=" my-4">
                <span className="font-semibold">Info</span>
                <div className="flex flex-col gap-2 rounded-md border  p-1 py-3 text-gray-600">
                  {panel.generalPanelProps?.map(
                    (item: GeneralPanelProps, i: number) => (
                      <div
                        key={i}
                        className="text-sm flex space-between w-full pl-4 pr-4"
                      >
                        <span className="flex-1 text-slate-400">
                          {item.title}{" "}
                        </span>
                        <span className="flex-1 font-bold  ">{item.value}</span>
                      </div>
                    )
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }
  return null;
};

function StopItemBody({ bodyItems }: StopPanelProps) {
  return (
    <div className="flex flex-col gap-2 mt-1">
      {bodyItems?.map((item: StopPanelPropsBodyItem, i: number) => (
        <div key={i} className={`flex-1 flex flex-col ${item.className}`}>
          <div className="flex-1 text-xs text-slate-400">{item.title}</div>
          <div className="flex-1 text-xs font-bold">{item.value}</div>
        </div>
      ))}
    </div>
  );
}

export default OrderDetailsWidget;

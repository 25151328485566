import { useEffect, useState } from "react";
import { useCourieStore } from "../../store";

export const useGoogleAutocompleteApi = (
  input: string | undefined,
  types?: string[],
  locationBias?: {
    center: {
      lat: number;
      lng: number;
    };
    radius: number;
  }
) => {
  const { showToast } = useCourieStore();
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  useEffect(() => {
    if (!input) {
      return;
    }

    if (typeof window === "undefined" || !window.google) {
      showToast({
        type: "error",
        message: "Something went wrong. Try reloading the page.",
      });
      return;
    }

    const autocompleteService = new google.maps.places.AutocompleteService();

    autocompleteService.getPlacePredictions(
      {
        input: input,
        types: types,
        // @ts-ignore
        locationBias: locationBias ? locationBias : undefined,
      },
      (predictions, status) => {
        if (
          status === google.maps.places.PlacesServiceStatus.OK &&
          predictions
        ) {
          setPredictions(predictions);
        }
      }
    );
  }, [input]);

  return { predictions };
};

import {
  ShipmentStopType,
  ShipmentType,
  StopCreateInput,
  StopTemplateCreateInput,
} from "@api/graphql/generated/generated-types";
import React, { useEffect } from "react";
import { defaultStopFormValues } from "../utils/formInitialStates";
import { Button } from "@src/common/components";
import { PlusIcon } from "@heroicons/react/24/outline";

type Props = {
  append: any;
  insert: any;
  shipmentType: ShipmentType;
  watchStops: any;
  onAddStop?: () => void;
  defaultStopObj?: StopCreateInput | StopTemplateCreateInput;
};

function AddStopButton({
  append,
  insert,
  shipmentType,
  watchStops,
  defaultStopObj,
  onAddStop,
}: Props) {
  useEffect(() => {
    if (watchStops?.length > 0) {
      onAddStop && onAddStop();
    }
  }, [watchStops?.length]);
  const handleAddStop = () => {
    const newStop = defaultStopObj
      ? { ...defaultStopObj }
      : { ...defaultStopFormValues };
    if (shipmentType === ShipmentType.MultiPickUp) {
      newStop.type = ShipmentStopType.PickUp;
      const nextDropOffIndex = watchStops.findIndex(
        (stop: any) => stop.type === ShipmentStopType.DropOff
      );
      if (nextDropOffIndex !== -1) {
        insert(nextDropOffIndex, newStop);
      } else {
        append(newStop);
      }
    } else if (
      shipmentType === ShipmentType.MultiDropOff ||
      shipmentType === ShipmentType.MultiDropOffRoundTrip
    ) {
      newStop.type = ShipmentStopType.DropOff;
      const lastDropOffIndex = watchStops
        .map((stop: any, index: number) =>
          stop.type === ShipmentStopType.DropOff ? index : -1
        )
        .reduce((acc, curr) => (curr > acc ? curr : acc), -1);
      if (lastDropOffIndex !== -1) {
        insert(lastDropOffIndex + 1, newStop);
      } else {
        append(newStop);
      }
    }
  };

  return (
    <>
      {shipmentType === ShipmentType.MultiPickUp ||
      shipmentType === ShipmentType.MultiDropOff ||
      shipmentType === ShipmentType.MultiDropOffRoundTrip ? (
        <Button
          size={"xs"}
          outline={true}
          className="border"
          onClick={() => handleAddStop()}
          data-cy="add-stop-button"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          {shipmentType === ShipmentType.MultiPickUp
            ? "Add Pickup"
            : "Add Dropoff"}
        </Button>
      ) : null}
    </>
  );
}

export default AddStopButton;

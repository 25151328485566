import React, { useState } from "react";
import { useContext, useEffect } from "react";
import { AuthContext } from "@src/auth/components/AuthProvider";
import {
  ServiceType,
  useGetServiceTypesLazyQuery,
} from "@api/graphql/generated/generated-types";
import { Select } from "@src/common/components";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";

type Props = {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  onChange?: (value: ServiceType | undefined) => void;
  size?: "mini" | "large" | "default" | "compact" | undefined;
  value?: any;
  setIdOnly?: boolean;
};

function ServiceTypeSelector({
  control,
  onChange: onChangeProp,
  size,
  value,
  setIdOnly = false,
}: Props) {
  const { courierId } = useContext(AuthContext);
  const { showToast } = useCourieStore();
  const [
    getServiceTypes,
    { data: getServiceTypesQueryData, loading: getServiceTypesQueryLoading },
  ] = useGetServiceTypesLazyQuery();
  const [initialValue, setInitialValue] = useState<ServiceType | null>(null);

  useEffect(() => {
    if (courierId) {
      getServiceTypes({
        variables: {
          courierId: courierId!,
        },
      }).catch((e) => {
        showErrorToast(e, showToast);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courierId]);

  useEffect(() => {
    if (value && getServiceTypesQueryData) {
      const foundServiceType = getServiceTypesQueryData.serviceTypes.find(
        (serviceType) => serviceType.id === value
      );
      setInitialValue((foundServiceType as ServiceType) || null);
    }
    if (value === undefined) {
      setInitialValue(null);
    }
  }, [getServiceTypesQueryData, value]);

  let formattedOptions: any[] = [];
  if (getServiceTypesQueryData) {
    formattedOptions = getServiceTypesQueryData.serviceTypes.map(
      (serviceType) => ({
        ...serviceType,
        name: `${serviceType.name} ${
          serviceType.deliveryTimeInMinutes
            ? `(${formatDeliveryTime(serviceType.deliveryTimeInMinutes)})`
            : ""
        }`,
      })
    );
  }

  function formatDeliveryTime(minutes: number) {
    if (minutes < 120) return `${minutes} min`;

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return `${hours} hr${hours > 1 ? "s" : ""}${
      remainingMinutes > 0 ? ` ${remainingMinutes} min` : ""
    }`;
  }

  return (
    <div className="flex">
      <Controller
        control={control}
        name="serviceTypeId"
        rules={{ required: "Service Type is required" }}
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          if (initialValue && !value && !setIdOnly) {
            onChange(initialValue);
          }
          return (
            <Select
              size={size || undefined}
              error={error ? true : false}
              options={formattedOptions}
              value={initialValue ? [initialValue] : value ? [value] : []} // Pass the entire object or objects
              labelKey="name"
              valueKey="id"
              isLoading={getServiceTypesQueryLoading}
              placeholder="Service Type"
              onChange={(params: any) => {
                const value: ServiceType | undefined = params.value[0];
                onChange(value);
                onChangeProp && onChangeProp(value);
              }}
            />
          );
        }}
      />
    </div>
  );
}

export default ServiceTypeSelector;

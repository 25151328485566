import { ShipmentStopType } from "@api/graphql/generated/generated-types";
import { DatePicker, Label } from "@src/common/components";
import moment from "moment";
import React, { useEffect, useMemo, useRef } from "react";
import { Control, Controller, useFormContext } from "react-hook-form";

interface Props {
  name: string;
  control: Control;
  stopType: ShipmentStopType;
  selectedDuration: string;
  onStopDateChange?: () => void;
  index;
  formPathName?: string;
}

const StopDateSelector = ({
  name,
  control,
  stopType,
  selectedDuration,
  onStopDateChange,
  index,
  formPathName = "",
}: Props) => {
  const { setValue, watch, getValues } = useFormContext();

  const shipmentDate = watch(`${formPathName}shipmentDate`);
  const memoizedShipmentDate = useMemo(() => shipmentDate, [shipmentDate]);
  let value = watch(name);

  if (typeof value === "string") {
    value = new Date(value);
  }

  useEffect(() => {
    const stopDate = getValues(`${formPathName}stops.${index}.stopDate`);
    if (selectedDuration === "hasStopDates" && memoizedShipmentDate) {
      if (stopDate) {
        return;
      }
      setStopDates();
    }
    if (selectedDuration === "noStopDates") {
      setValue(`${formPathName}stops.${index}.stopDate`, null);
    }
  }, [selectedDuration, memoizedShipmentDate, setValue, name, stopType]);

  const setStopDates = () => {
    if (stopType === ShipmentStopType.PickUp) {
      setValue(name, memoizedShipmentDate);
    } else {
      const nextDay = moment(memoizedShipmentDate).add(1, "days");
      const closeTime = getValues(
        `${formPathName}stops.${index}.timeWindow.close`
      );
      const closeTimeInMoment =
        closeTime &&
        (() => {
          if (!(closeTime instanceof Date) && !isNaN(Number(closeTime))) {
            return moment(parseInt(closeTime) * 1000);
          }
          return moment(closeTime);
        })();
      const deliverByDate = closeTimeInMoment?.toDate() || nextDay.toDate();
      setValue(`${formPathName}stops.${index}.deliverByDate`, deliverByDate);
      setValue(name, moment(memoizedShipmentDate).toDate());
    }
  };

  return (
    <div className="flex flex-col">
      <Label className="truncate text-xs">Route Date</Label>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <DatePicker
            size="mini"
            value={value}
            onChange={(value) => {
              if (value) {
                onChange(value.date);
                onStopDateChange && onStopDateChange();
              }
            }}
            minDate={memoizedShipmentDate}
            clearable={false}
          />
        )}
      />
    </div>
  );
};

export default StopDateSelector;

import { PackageSize } from "@api/graphql/generated/generated-types";

export function displayPackageSize(size: PackageSize | undefined): string {
  if (!size) {
    return "";
  }

  switch (size) {
    case PackageSize.Xs:
      return "XS (Envelope)";
    case PackageSize.S:
      return "S (Small Item)";
    case PackageSize.M:
      return "M (Medium Box)";
    case PackageSize.L:
      return "L (Van needed)";
    case PackageSize.Xl:
      return "XL (Box Truck needed)";
    case PackageSize.Xxl:
      return "XXL (Truck needed)";
  }
}

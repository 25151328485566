import {
  CreateOneOffOrderMutationVariables,
  OrderAcquisitionType,
  ShipmentStopType,
  ShipmentType,
  StopCreateInput,
} from "@api/graphql/generated/generated-types";

export const OneOffOrderDefaultValues: CreateOneOffOrderMutationVariables = {
  acquisitionType: OrderAcquisitionType.User,
  caller: "",
  costCenterId: "",
  courierId: "",
  endCustomerId: "",
  endCustomerReferenceNumber: "",
  notes: "",
  orderTypeId: "2",
  serviceTypeId: "",
  shipments: [
    {
      shipmentDate: new Date(),
      stops: [
        {
          city: "",
          contactName: "",
          companyName: "",
          country: "",
          googlePlaceId: "",
          instruction: "",
          phone: "",
          state: "",
          stopTemplateId: "",
          streetAddress: "",
          suite: "",
          timeWindow: {
            open: null,
            close: null,
          },
          type: ShipmentStopType.PickUp,
        },
        {
          city: "",
          contactName: "",
          companyName: "",
          country: "",
          googlePlaceId: "",
          instruction: "",
          phone: "",
          state: "",
          stopTemplateId: "",
          streetAddress: "",
          suite: "",
          timeWindow: {
            open: null,
            close: null,
          },
          type: ShipmentStopType.DropOff,
        },
      ],
      type: ShipmentType.Simple,
      packageSize: undefined,
      packageWeightInPounds: undefined,
      packageTypeId: undefined,
      numPackages: undefined,
      packageDescription: undefined,
    },
  ],
};

export const defaultStopFormValues: StopCreateInput = {
  city: "",
  contactName: "",
  country: "",
  googlePlaceId: "",
  instruction: "",
  phone: "",
  state: "",
  stopTemplateId: "",
  streetAddress: "",
  suite: "",
  timeWindow: {
    open: null,
    close: null,
  },
  type: ShipmentStopType.DropOff,
};

export const defaultStopTemplateFormValues = {
  companyName: "",
  contactName: "",
  googlePlaceId: "",
  instruction: "",
  phone: "",
  suite: "",
  streetAddress: "",
  timeWindow: {
    openSecondsSinceMidnightUtc: null,
    closeSecondsSinceMidnightUtc: null,
  },
  type: ShipmentStopType.DropOff,
};

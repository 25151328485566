import { Input as BaseInput, InputProps } from "baseui/input";

export interface DollarInputProps extends InputProps {
  dollar: number;
  setDollar: (dollar: number) => void;
}

const DollarInput: React.FC<DollarInputProps> = ({
  dollar,
  setDollar,
  onChange,
  ...props
}) => {
  return (
    <BaseInput
      startEnhancer="$"
      value={`${dollar.toFixed(2)}`}
      onChange={(event) => {
        let value = event.target.value;
        if (onChange) {
          onChange(event);
        }
        let num: number = 0.0;
        if (event.target.value) {
          const numValue = event.target.value.replace(/[^\d-]|(?!^)-/g, "");
          num = numValue === "" ? 0.0 : Number(numValue);
        }
        setDollar(num / 100);
      }}
      {...props}
      overrides={{
        Input: {
          style: {
            backgroundColor: "rgb(249 250 251)",
          },
        },
        ...props.overrides,
      }}
    />
  );
};

export default DollarInput;
